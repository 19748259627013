
.auto-center {
	margin: auto;
}
.landing {
	color: $w-black;
	.section-constrainer {
		max-width: 1500px;
		margin: auto;
	}
	#hero {
		position: relative;
		height: 100vh;
		background-size: cover;
		background-position: bottom center;
		padding-top: 80px;
		.hero-back {
			position:absolute;
			bottom: 0;
			z-index: -1;
			width: 100%;
			height: auto;
		}
		>div:not(.sams) {
			@include flexbox;
			@include flex-direction(column);
			@include align-items(center);
			margin-left: auto;
			margin-right: auto;
			position: relative;
			&:before {
				content: "";
				position:absolute;
				width: 100%;
				height: 100%;
				max-width: 45vh;
				background-image: url(../img/logo-solid-wh_lg.png);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				opacity: .2;
				z-index:0;
			}
			
			>div {
				@include flexbox;
				@include flex-direction(column);
				@include align-items(center)
				z-index: 10;
			}
			h1 {
				color: $w-gold;
				font-weight: $semibold;
				font-size: 1.6rem;
				line-height: 2.8rem;
				text-shadow: 2px 2px 3px transparentize(black, .5);
				padding: 0 3rem 1.2rem;
				margin-top: auto;
				opacity: .9;
			}
			.fine-print {
				margin-top: auto;
				p {
					color: lighten($w-black, 50%);
					margin-bottom: 0;
					&:last-child {
						font-size: 14px;
						color: lighten($w-black, 30%);
						margin-bottom: 3.25rem;
					}
				}
			}
			.cta, .mobile-cta {
				padding: 0 2rem;
			}
			.mobile-cta { display: none;}
		}
		.sams {
			@include flexbox;
			@include align-items(center);
			position: absolute;
			top: 20px;
			right: 120px;
			font-size: 14px;
			color: white;
			opacity: .6;
			padding-right: 1rem;
			border-right: 1px solid white;

			img {
				width:auto;
				margin-top: -6px;
				height:42px;
				opacity: .9;
				margin: 0 .667rem;
			}
			p {
				margin-bottom: 0px;
				line-height: 19px;
			}
		}
	}
	section:not(#hero), section:not(.product) {
		// max-width:1800px;
		margin: auto;
	}
	#headline, #stats {
		.ui-toggle {
			position: absolute;
			right: 2rem;
		}
		background-color: $w-khaki-light;
		h2, p {
			color: $w-black;
		}
		.headline-msg {
			border-bottom: 1px solid lighten($w-black, 20%);
		}
		&.dark {
			background-color: $w-black;
			h2, p {
				color: lighten($w-black, 60%);
			}
			.headline-msg {
				border-bottom-color: lighten($w-black, 60%);
			}
			.courses {
				background-image: url(../img/courses_ill-fill.png) !important;
			 	& ~ h3 { color: $w-gold !important; }
			}
		}
	}
	#headline {
		border-top: 3px solid lighten($w-black, 60%);
		.section-constrainer {
			>div {
				padding-top: 1.5rem;
			}
		}
		.headline-msg {
			padding-bottom: 1rem;
			h2 {
				font-size: 1.4rem;
				font-weight: $semibold;
				margin-bottom: 1.5rem;
			}
			p {
				text-align: justify;
				&:first-of-type { padding-right: 1.5rem; }
				&:last-of-type { padding-left: 1.5rem; }

			}
		}
	}
	#stats {
		.vert-layout { display: none; }
		padding-bottom: 2rem;
		.medium-4 {
			padding: 0 1.5rem;
			text-align: center;
			p {
			}
		}
		.stat-ball {
			width: 125px;
			height: 125px;
			margin: 2rem auto .5rem;
			padding: 2.2rem 1rem;
			color: white;
			font-weight: $semibold;
			font-size: 1.3125rem;
			line-height: 1;
			background-size: contain;
			background-repeat: no-repeat;
			span { 
				padding-top: .333rem;
				font-weight: 400; 
				font-size: 1rem;
			}
			&.courses {
				& ~ h3 {
					color: $w-black;
				}
			}
			&.students {
				& ~ h3 {
					color: $w-blue;
				}
			}
			&.time {
				& ~ h3 {
					color: $w-blue;
				}
			}
			&.lessons {
				& ~ h3 {
					color: $w-rose;
					text-shadow: 0 0 3px rgba(0,0,0,.4) !important;
				}
			}
		}
		&.fill {
			.stat-ball {
				&.courses {
					background-image: url(../img/courses_ill-fill-dark.png);
				}
				&.students {
					background-image: url(../img/students_ill-fill.png);
				}
				&.lessons {
					background-image: url(../img/lessons_ill-fill.png);
				}
				&.time {
					background-image: url(../img/time-ill-fill.png);
				}
			}
		}

		h3 {
			font-size: 1.4rem;
			font-weight: $semibold;
			margin-bottom: .625rem;
		}
		p {
			padding: 0;
		}
		&.alt-layout {
			padding: 2rem 0 3rem;
			.hr-layout { display: none; }
			.vert-layout { display: initial; }
			.auto-center > div {
				@include flexbox;
				@include align-items(center);
				&:not(:first-child) { margin-top: 2rem;}
				.stat-ball {
					@include flex-shrink(0);
					margin: 0 2rem 0 0;
					&.courses {
						& ~ div h3 {
							color: $w-black;
						}
					}
					&.students {
						& ~ div h3 {
							color: $w-blue;
						}
					}
					&.time {
						& ~ div h3 {
							color: $w-blue;
						}
					}
					&.lessons {
						& ~ div h3 {
							color: $w-rose;
						}
					}
				}
				p {
					margin-bottom: 0;
				}
			}
		}
	}
	#industry-bar {
		background-color: $w-gold;
		padding-top: 3rem;
		padding-bottom: 3rem;
		// border-bottom: 5px solid white;
		.small-9 { margin: auto;}
		h3 {
			font-size: 1.6rem;
			font-weight: $semibold;

		}
		p {
			text-align: justify;
			margin-bottom: 0;
		}
	}
	#product-tiles {
		background-color: $w-khaki-light;
		padding-bottom: 5rem;
		.section-constrainer { 
			@include flexbox;
			margin: auto;
			position: relative;
			perspective:1000px;
			// &:first-of-type:before {
			// 	position: absolute;
			// 	content: "";
			// 	width: 100%;
			// 	height: 2px;
			// 	background-color: white;
			// }
			.product {
				display: inline-flex;
				width: 45.833%;
				.tile-frame {
					@include flexbox;
					@include flex-direction(column);
					@include flex-grow(1);
					position: relative;
					margin-top: 3rem;
					background-color: $w-black;
					border-radius: 15px;
					padding: 20px 40px 30px;
					box-shadow: 2px 3px 5px rgba(0,0,0,.3);
					transition: transform $trans-fast, box-shadow $trans-fast;
					transform-style: preserve-3d;
					backface-visibility: hidden;
					.product-name {
						padding-left: 100px;
						min-height: 115px;
						h2 {
							font-size: 24px;
							font-weight: bold;
							color: $w-gold;
						}
						h6 {
							font-weight: $semibold;
							color: white;
						}
					}
					.description {
						@include flex-grow(1);
						p {
							color: white;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
						a { color: lighten($w-blue, 10%); }
					}
					.cta-wrapper {
						margin-top: 1rem;
						width: 100%;
						text-align: center;
						p {
							color: white;
						}
						a.btn {
							box-shadow: none;
							color: $w-black;
							font-weight: $semibold;
							background-color: $w-gold;
							width: 50%;
						}
					}
					&:before {
						content: "";
						position: absolute;
						display: block;
						left: -30px;
						top: -15px;
						width: 140px;
						height: 140px;
						border-radius: 50%;
						background-color: white;
						border: 3px solid $w-black;
						background-color: darken($w-khaki-light, 10%);
						background-size: contain;
						background-repeat:no-repeat;
						background-position:center;
					}
					&.hov-effect {
						transform: rotateY(3deg);
						box-shadow: -2px 3px 7px rgba(0, 0, 0, 0.3);
						.description {
							a { color: lighten($w-blue, 25%); }
						}
					}
				}
				&:nth-child(even) {
					margin-left: 8.33%;
				}
				&.power {
					.tile-frame:before {
						background-image: url(../img/helmet_ill.png);
					}
				}
				&.water {
					.tile-frame:before {
						background-image: url(../img/anchor_ill_b.png);
					}
				} 
				&.pelo {
					.tile-frame:before {
						background-image: url(../img/bike_ill.png);
					}
				}
				&.process {
					.tile-frame:before {
						background-image: url(../img/process_ill.png);
					}
				}
			}
			&:nth-of-type(odd) .product:nth-child(even), &:nth-of-type(even) .product:nth-child(odd) {
				.tile-frame {
					background-color: lighten($w-black, 20%);

					.description a { 
						color: lighten($w-blue, 15%);
						text-shadow: 0 0 3px rgba(0,0,0,.25);
					}
				}
			}
		}
	}
	#test-drive-banner {
		border-top: 3px solid lighten($w-black, 10%);
		padding: 2rem 0;
		background-color: $w-gold;
		text-align: center;
		color: white;
		h4 {
			font-size: 2.8125rem;
			font-weight: $semibold;
		}
		p:not(.note) {
			font-size: 1.3125rem;
			margin-bottom: 0;
		}
		span {
			color: $w-blue;
		}
		a { margin: 1.5rem; }
		.note { color: $w-black;}
		.mobile-cta { display: none;}
	}
		
	@media only screen and (min-width : 1920px) {
		#hero {
			h1 {
				font-size: 2rem;
				line-height: 1.8;
				margin-bottom: -2rem;
				max-width: 1500px;
			}
			a {
				margin-top: 2rem;
			}
		}
	}

	@media only screen and (min-width : 400px) and (orientation:portrait) {
		.img-wrapper img, .hero-back {
			width: auto !important;
			max-width: unset !important;
			height: 100% !important; 
		}
		#dock .img-wrapper img {
			transform: translateX(50%);
		}
	}
}





