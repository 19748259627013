.curriculum {
	.head-bar {
		background-color: $w-black;
	}
	.splash {
		padding-top: calc(80px + 2.4rem);
		padding-bottom: 2.4rem;
		background-color: $w-khaki-light;
		border-bottom: 2px solid $w-black;
		.narrative {
			color: $w-black;
			padding-right: 8%;
			h1 {
				font-size: 28px;
				font-weight: bold;
				line-height: 36px;
				margin-bottom: 1rem;
			}
			p {
				font-size: 1.2rem;
				margin-bottom: 5px;
			}
			.cta-btn {
				display: none;
				margin: 0 auto;
				padding: 1rem 0;
				position: relative;
				text-align: center;
				a {
					background-color: $w-gold;
					color: #606060;
					font-weight: $semibold;
				}
			}
			a {
				font-style: italic;
				color: transparentize($w-blue, .3);
				transition: color $trans-typical;
				&:hover {
					color: $w-blue;
				}
			}
		}
	}
	.series-break {
		border-bottom: 1px solid #ebebeb;
		margin-bottom: 5px !important;
	}
	.course-list {
		@mixin box-shadow {
			box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
		}
		padding-top: 1rem;

		padding-bottom: 90vh;
		
		h2 {
				font-size: 1.4rem;
				font-weight: bold;
				color: $w-gold;
			}

		.course {
			.summary { 
				padding-right: 4%; 
				padding-left: 3px;
				position: relative;
				z-index: 1;
				margin-bottom: 1rem;
				h3 {
					font-size: 1rem;
					font-weight: $semibold;
					color: $w-black;
					margin-bottom: 3px;
				}
				
				p {
					margin-bottom: 0;
					padding-left: 1rem;
					color: $light-gray;
					a {
						font-style: italic;
						padding-left: .5rem;
						color: transparentize($w-blue, .4);
						transition: color $trans-typical;
						&:hover {
							color: $w-blue;
						}
					}
				}
				&:before {
					@include box-shadow;
					content: "";
					visibility: hidden;
					position: absolute;
					left: -10px;
					top: -7px;
					width: calc(93% + 20px);
					height: calc(100% + 13px);
					z-index: -1;
					opacity: 0;
					transition: opacity .3s ease;
					background-color: $w-khaki-light;
					border: 3px solid $w-gold;
					border-radius: 10px;
				}
				&:after {
					box-shadow: 0 1px 3px 2px rgba(0,0,0,.1);
					content: "";
					visibility: hidden;
					position: absolute;
					right: 0;
					top: calc(50% - 2px);
					width: calc(7% - 10px);
					height: 3px;
					opacity: 0;
					transition: opacity .3s ease;
					background-color: $w-gold;
				}
			}
			.details {
				position: relative;
				color: $medium-gray;
				.details-container {
					@include box-shadow;
					position: relative;
					visibility: hidden;
					position: absolute;
					z-index: 10;
					margin-top: -.5rem;
					padding: .5rem 0 .5rem 1.2rem;
					border: 3px solid $w-gold;
					border-radius: 10px;
					background-color: white;
					transition: visiblity 1s ease;
					opacity: 0;
					transition: opacity $trans-typical;
					.close-trigger {
						cursor: pointer;
						position: absolute;
						right: -12px;
						top:-10px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 30px;
						height: 30px;
						border-radius: 15px;
						background-color: $w-gold;
						border: 1px solid white;
						font-weight: bold;
						color: white;
					}
					.scroll-container {
						padding-right: 1.2rem;
						max-height: 90vh;
						overflow-y: scroll;
					}
					p {
						margin-bottom: 5px;
					}
					div div {
						font-weight: $semibold;
						color: $dark-gray;
					}
					ol {
						margin-bottom: 0;
						margin-left: 1.75rem;
					}
					&:before {
						@include box-shadow;
						z-index: -1;
						content: "";
						position: absolute;
						right: -12px;
						top:-10px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 30px;
						height: 30px;
						border-radius: 15px;
					}
				}
			}
			&.show-more {
				.summary {
					p {
						transition: color $trans-typical;
						color: $medium-gray;
					}
					&:before, &:after {
						visibility: visible;
						opacity: 1;				
					}
					a {
						display: none;
					}
				}
				.details-container {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
	.curriculum-footer {
		z-index: 20;
		position:fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		background-color: $w-black;
		.cta-btn {
			margin: 0 auto;
			padding: 1rem 0;
			position: relative;
			a {
				background-color: $w-gold;
				color: $w-black;
				font-weight: $semibold;
			}
			&:before {
				content: "Ready to level up your business?";
				position: absolute;
				right: calc(100% + 1rem);
				font-size: 1.2rem;
				color: white;
				font-weight: $semibold;
				text-wrap: nowrap;
				top: 1.4rem;
			}
		}
		.contact {
			position: absolute;
			top: 1.667rem;
			right: 8.3%;
			color: white;
			a {
				color: $w-gold;
			}
		}
	}
	@media only screen and (min-width: 2000px) {
		.narrative {
			.cta-btn {
				display: block !important;
			}
		}
	}
	@media only screen and (max-width: 700px) {
		.splash {
			.trailer-wrapper {
				margin-top: 1rem;
			}
			a {
				display: none;
			}
		}
		.section-limiter {
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.course-list {
			padding-bottom: 10vh;
			h2 {
				margin-left: 0;
			}
			.course {
				.summary p a {
					display: none;
				}
				.summary:before, .summary:after {
					display: none;
				}
				.details {
					display: none;
				}
			}
		}
	}
}