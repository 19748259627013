.auto-center {
	margin: auto;
}
.section-constrainer {
	max-width:1200px;
	margin: 0 auto;
}
.about {

	#hero {
		position: relative;
		min-height: 500px;
		color: white;
		background-size: cover;
		background-position: bottom center;
		padding-top: 100px;
		padding-bottom: 2.667rem;
		.hero-back {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: bottom center;
			z-index: -1;
		}
		.copy-wrapper {
			margin: auto;
			max-width: 1200px;
		}
		.primary-copy {
			color: white;
			opacity: .9;
			h1 {
				font-size: 24px;
			}
			.copy-wrapper {
				column-count: 2;
				column-gap: 3rem;
				text-align: justify;
			}
		}
		.social-container {
			width: 100%;
			text-align: center;
			margin-top: 2rem;
			z-index: 10;
			a {
				display: inline-block;
				width: 30px;
				height: 30px;
				background-size: contain;
				opacity: .7;
				background-repeat: no-repeat;
				transition: opacity $trans-typical;
				&:hover, &:focus {
					opacity: 1;
				}
				&.fb-link {
					background-image: url(../img/fb-link.png);
				}
				&.linked-link {
					background-image: url(../img/linked-link.png);
					margin: 0 1rem;
				}
				&.insta-link {
					background-image: url(../img/insta-link.png);
				}
			}
		}
	}
	section {
		&:not(#hero) {
			padding-top: 50px;
		}
		.section-head {
			h1 {
				font-size: 1.6rem;
				font-weight: $semibold;
				color: $w-black;
			}
		}
		.section-limiter {
			max-width: 1500px;
			margin: auto;
		}
		&#whoWeAre {
			background-color: $w-gold;
			color: $w-black;
			padding-bottom: 2rem;
			.section-head p {
				margin-bottom: 3rem;
			}
			.people {
				margin-bottom: 4rem;
			}
			.tile-frame {
				position: relative;
				&:nth-of-type(odd) {
					padding-right: 3rem;
				}
				&:nth-of-type(even) {
					padding-left: 3rem;
				}
				.tile {
					background-color: $w-black;
					border-radius: 15px;
					padding: 2rem 2.67rem;
					color: white;
					position: relative;
					.profile-pic {
						position: absolute;
						left: -2rem;
						top: -2rem;
						width: 150px;
						height: 150px;
						border-radius: 50%;
						background-position: center;
						background-size: cover;
						border: 3px solid $w-black;
					}
					.name-and-title {
						padding-left: 6.5rem;
						h2 {
							color: $w-gold;
							font-size: 1.6rem;
							font-weight: bold;
							margin: 0 0 .67rem;
							line-height: 1;
						}
						h6 {
							font-size: 1.2rem;
							margin: 0 0 3rem;
							line-height: 1;
						}
						.contact {
							position: absolute;
							margin-top: -2.667rem;
							a {
								color: #26a6e4;
							}
						}
					}
					p {
						margin:0;
					}
				}
				&#brandi {
					.profile-pic {
						background-image: url(../img/brandi-profile.jpg);
					}
				}
				&#tj {
					.profile-pic {
						background-image: url(../img/tj-profile.jpg);
					}
				}
				&#peter {
					.profile-pic {
						background-image: url(../img/peter-profile.jpg);
					}
				}
				&#siobhan {
					.profile-pic {
						background-image: url(../img/siobhan-profile.jpg);
					}
				}
				&#eddy {
					.profile-pic {
						background-image: url(../img/eddy-profile.jpg);
					}
				}
				&#lorna {
					.profile-pic {
						background-image: url(../img/lorna-profile.jpg);
					}
				}
				&#caleb {
					.profile-pic {
						background-image: url(../img/caleb-profile.png);
					}
				}
			}
			#tj, #peter, #caleb {
				.tile {
					background-color: lighten($w-black, 20%);
				}
			}
		}
		&#industry-partners {
			position: relative;
			background-color: $w-khaki-light;
			color: $w-black;
			padding-bottom: 3rem;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100vw;
				border-top: 3px solid $w-black;
			}
			.section-head p {
				margin-bottom: 0;
			}
			.partner {
				margin-top: 2.33rem;
				.identity {
					padding-top: .667rem;
					.image-container {
						height: 100%;
						width: 75%;
						max-width: 120px;
						background-size: contain;
						background-repeat: no-repeat;
					}
				}
				.details {
					p {
						margin-bottom: .33rem;
					}
				}
				&.garage-composites .image-container{
					background-image: url(../img/gc-logo.svg);
				}
				&.leadhelm .image-container{
					background-image: url(../img/leadhelm-logo.png);
				}
				&.mraa .image-container{
					background-image: url(../img/mraa-logo.png);
				}
				.details {
					h2 {
						font-size: 1.6rem;
						font-weight: $semibold;
					}
					.contact {
						a {
							color: #26a6e4;
						}
					}
				}
			}
			.customers {
				margin-top: 2rem;
				padding: 2rem 0;
				border-top: 2px solid $w-black;
				.copy {
					p {
						margin-bottom: 2rem;
					}
					span {
						font-size: 1.2rem;
						font-weight: $semibold;
					}
				}
				.logos {
					@include flexbox;
					@include justify-content(center);
					img {
						height: 35px;
						&.smaller {
							height: 25px;
						}
						&:not(:last-child) {
							margin-right: 2.667rem;
						}
					}
				}
			}
		}
		&#meet-the-trainers {
			position: relative;
			background-image: url(../img/trainers-background.jpg);
			background-size: cover;
			background-repeat: no-repeat;
			color: white;
			padding-bottom: 3rem;
			.section-head {
				h1 {
					color: white;
				} 
				p {
					margin-bottom: 0;
				}
			}
			.trainer {
				margin-top: 2.33rem;
				.profile-pic {
					padding-top: .667rem;
					.image-container {
						opacity: .8;
						height: 100%;
						width: 75%;
						max-width: 120px;
						background-size: contain;
						background-repeat: no-repeat;
					}
				}
				.details {
					.headline {
						@include flexbox;
						@include align-items(baseline);
						ul  {
							list-style-type: none;
							margin: 0 0 0 1rem;
							&:before {
								content: "(";
							}
							&:after {
								content: ")";
							}
							li{
								display: inline;
								text-transform: uppercase;
								font-size: 12px;
								&:not(:last-child) {
									margin-right: 5px;
									&:after {
										content: ",";
									}
								}
							}
						}
					}
					p {
						margin-bottom: .33rem;
					}
				}
				&.sam .image-container{
					background-image: url(../img/sam-d-profile-bw.png);
				}
				&.andrew .image-container{
					background-image: url(../img/andrew-h-profile-bw.png);
				}
				&.john .image-container {
					background-image: url(../img/john-g-profile-bw.png);
				}
				&.kristen .image-container {
					background-image: url(../img/kristen-m-profile-bw.png);
				}
				.details {
					h2 {
						font-size: 1.6rem;
						font-weight: $semibold;
					}
				}
			}
		}
	}
}





















