//colors//

$w-blue: #1889bf;
$w-khaki: #9b956d;
$w-khaki-light: #d9d7c8;
$w-rose: #975959;
$w-gold: #f5c32b;
$w-black: #4c4949;
$w-black-dark: #373637;
$semibold: 500;
$light: 300;
$trans-typical: .3s ease;
$trans-fast: .15s ease;
$light-gray: #909090;
$medium-gray: #606060;
$dark-gray: #303030;