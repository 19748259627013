.product-page {
	h2 {
		font-size: 1.6rem;
		font-weight: $semibold;
		color: $w-black;
	}
	.mobile-cta { display: none;}
	#hero {
		min-height: 100vh;
		background-size: cover;
		padding-top: 80px;
		position: relative;
		.product-summary {
			background-color: transparentize(black, .3);
			color: white;
			padding: 2rem 4.5rem 0;
			h1 {
				font-size: 2rem;
				font-weight: bold;
				margin: 0;
				line-height: 1.667rem !important; 
				margin-bottom: 10px;
			}
			.subtitle {
				margin-bottom: 1rem;
				font-weight: $semibold;
			}
			.p {}
			.vid-wrapper {}
			.cta-wrapper {
				padding-top: 3rem;
				div {
					text-align: center;
					font-size: 18px;
					&.cta-btn {
						padding-left: 1rem;
					}
					&:not(.cta-btn) {
						padding-right: 1rem;
					}
					a.big, button {
						background-color: $w-gold;
						color: $w-black;
						width: 100%;
						height: 40px;
						border-radius: 20px;
						font-weight: $semibold;
					}
					&.note {
						margin-top: 2rem;
						font-size: 12px;
						width: 100%;
						color: transparentize(white, .2);
					}
				}
			}
		}
		.scroll-hint {
			width: 100%;
			text-align: center;
		}
	}
	&.peloton #hero {
		background-position: bottom;
	}
	.section-limiter {
		max-width: 1500px;
		margin: auto;
	}
	#details {
		background-color: $w-khaki-light;
		color: $w-black;
		padding: 3rem 0;
		p {
			text-align: justify;
		}
		&.series-highlight {
			.overview-block {
				margin-bottom: 1rem;
			}
			.overview-text {
				p {
					margin-bottom: 5px;
				}
			}
			.sub-description {
				padding-left: 3rem;
			}
			.series-block {
				margin: 0 auto 2rem;
				p.series-title {
					text-align:left;
					margin-bottom: 3px;
				}
				.two-by-vid {
					display: flex;
					padding-left: 2rem;
					.mod-vid-grid {
						width: calc(50% - 1rem);
						margin-left: 1rem;
					}
				}
			}
		}
	}
	#vid-scroll {
		max-width: 1500px;
		margin: 2rem auto 0;
		.section-constrainer {
			padding: 2rem 0 3rem;
			border-bottom: 1px solid $w-khaki-light;
		}
		.vid-grid-row {
			.cell {
				position: relative;
				float: left;
				&:first-child {
					padding-right: 2rem;
				}
				&:nth-child(2) {
					padding: 0 1rem;
				}
				&:last-child {
					padding-left: 2rem;
				}
				.vid-label {
					font-size: .933rem;
					color: $w-black;
					text-align: center;
					padding-top: 5px;
				}
			}
			&:nth-child(2){
				margin-top:2rem;
			}
		}

		.orbit-wrapper {
			.orbit-controls, & + .orbit-bullets { display: none; }
			.orbit-controls {
				button {
					background-color: $w-black;
					border-radius: 50%;
					opacity: .5;
					transition: opacity $trans-typical;
					transform: translateY(calc(-50% - 1rem));
					&:hover {
						opacity: 1;
						cursor: pointer;
					}
					&.orbit-previous {
						left: -2rem;
					}
					&.orbit-next {
						right: -2rem;
					}
				}
			}
			ul.orbit-container {
				li.orbit-slide {
					.cell {
						position: relative;
						float: left;
						&:first-child {
							padding-right: 2rem;
						}
						&:nth-child(2) {
							padding: 0 1rem;
						}
						&:last-child {
							padding-left: 2rem;
						}
						.vid-label {
							font-size: .933rem;
							color: $w-black;
							text-align: center;
							padding-top: 5px;
						}
					}
				}
			}
			&.has-slides {
				padding-bottom: 10px;
				.orbit-controls {display: block;}
				& + .orbit-bullets { @include flexbox; }
			}
		}
		.orbit-bullets {
			position: absolute;
			width: 100%;
			@include justify-content(center);
			button {
				background-color: transparent;
				width: 1rem;
				height: 1rem;
				border: 1px solid $w-black;
				border-color: lighten($w-black, 30%);
				margin: .25rem;
				&.is-active {
					background-color: lighten($w-black, 40%);
				}
			}
		}
	}
	#trainers {
		padding: 3rem 0;
		color: $w-black;
		.trainer-profile {
			p { 
				text-align: justify; 
				span { font-weight: bold; }
			}
			.inline-contact-link { 
				font-weight: bold;
			}
		}
		.profile-wrapper {
			min-height: 150px;
			margin-top: -1rem;
		}
		.trainer-pic {
			background-size: contain;
			background-repeat: no-repeat;
			height: 100%;
			max-height: 200px;
			position: relative;
			&.sam {
				background-image: url("../img/sam-d-profile.png");
			}
			&.steve {
				background-image: url("../img/steve-d-profile.png");
			}
			a {
				position: absolute;
				width: 100%;
				height: 100%;
			}
			&.andrew {
				background-image: url('../img/andrew-h-profile.png');
			}
			&.john {
				background-image: url('../img/john-g-profile.png');
			}
			&.kristen {
				background-image: url('../img/kristen-m-profile.png');
			}
		}
		.contact-link {
			padding-left: 20px;
			background-image: url('../img/email-icon.png');
			background-position: left;
			background-repeat: no-repeat;
			background-size: 1rem;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	#additional-content {
		.section-limiter { width: 100%;}
		padding: 3rem 0;
		background-color: $w-black;
		color: $w-khaki-light;
		border-bottom: 2px solid white;
		h2 {
			color: $w-khaki-light;
			margin-bottom: 2rem;
		}
		.video-grid {
			margin-bottom: 0;
		}
		.resource-group {
			.video-group {
				margin-bottom: 2rem;
				&:nth-child(odd) {
					padding-right: 1.5rem;
				}
				&:nth-child(even) {
					padding-left: 1.5rem;
					border-left: 1px solid transparentize($w-khaki-light, .8);
				}
				.vid-cell {
					padding-right: 1rem;
				}
				.vid-description {
					p { 
						text-align: justify; 
						font-size: .933rem;
					}
				}
			}
			a.load-resources {
				color: $w-gold;
				font-style: italic;
			}
		}
	}
	&.garage {
	}
	&.boss {
		#trainers {
			max-width: 1500px;
			margin: 0 auto;
			.section-limiter {
				margin-bottom: 1rem;
			}
			.profile-wrapper {
				margin-top: unset;
				padding-top: 15px;
			}
			.trainer-pic {
				max-height: 160px;
			}
			.trainer-profile {
				padding-top: 1rem;
			}
		}
	}
}