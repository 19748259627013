.em-1 {
	color: $w-gold;
	font-weight: bold;
}
.em-2 {
	font-size: 1.2rem;
	font-weight: bold;
	color: $w-gold;
}
.em-3 {
	color: $w-gold;
}
.left-block {
	padding: 2.33rem 4rem 0;
	background-color: rgba(0,0,0,.7);
	p {
		color: white;
	}
	.section-1 {
		h1 {
			margin-bottom: 0;
			font-size: 2rem;
			color: $w-gold;
			font-weight: bold;
			text-align: center;
		}
		p {
			margin-top: 2rem;
		}
	}
	.section-2 {
		ul {
			color: white;
		}
		.cta-wrapper {
			color: white;
			p {
				font-size: 18px;
				text-align: center;
			}
			.cta-btn {
				padding: .75rem 3rem 0;
				a {
					background-color: $w-gold;
					font-weight: $semibold;
					padding: 0 2rem;
					color: $w-black
				}
			}
			.note {
				margin-top: 1rem;
				font-size: 13px;
			}
		}
	}
}
.right-block {
	padding: 2.33rem 4rem 0;
	background-color: rgba(0,0,0,.3);
	p {
		color: white;
		&:last-of-type {
			border-top: 1px solid white;
		}
	}
	.video-pane .row {
		margin-bottom: 1rem;
		.cell{
			cursor: pointer !important;
		}
		>div:first-child {
			padding-right: .75rem;
		}
		>div:nth-child(2) {
			padding-left: .75rem;
		}
		.vid-label {
			color: $w-gold;
			font-size: 13px;
			font-weight: 500;
		}
	}
}
.club20 {
	#hero {
		height: 100vh;
		overflow: hidden;
		>div {
			height: 100%;
			overflow-y: auto;
		}
	}
	.left-block { 
		background-color: rgba(0,0,0,.5);
		padding-bottom: 1rem;
		& > div {
			max-width: 800px;
			margin-left: auto;
			margin-right: auto;
		}
		.section-2 {
			.column-wrapper {
				margin-bottom: 1.5rem;
			}
			.left-column {
				padding-right: 10px;
				ul {
					margin-bottom: 0;
				}
			}
			.right-column {
				@include flexbox;
				@include flex-direction(column);
				text-align: center;
				padding-left: 1rem;
				.eddy-profile {
					@include flex-grow(1);
					background-image: url(../img/eddy-profile.jpg);
					background-size: cover;
					border-radius: 3px;
					background-position: center;
				}
				a {
					font-size: 13px;
					font-style: italic;
				}
			}
		} 
		.cta-wrapper {
			@include align-items(center);
			border-top: 1px solid $w-gold;
			padding-top: 10px;
			p{
				color: white;
				font-weight: $semibold;
				color: $w-gold;
				margin-bottom: 0;
				text-align: center;
			}
		}
		.cta-btn {
			padding: .75rem 3rem 0;
			a {
				background-color: $w-gold;
				font-weight: $semibold;
				padding: 10px 2rem;
				color: $w-black;
				line-height: 20px;
				text-align: center;
				height: unset;
			}
		}
	}
	.right-block {
		background-color: rgba(0,0,0,.8);
		h1 {
			margin-top: 3px;
			margin-bottom: 10px;
			font-size: 2rem;
			color: $w-gold;
			font-weight: bold;
		}
		.section-header {
			margin-bottom: 10px;
			color: $w-gold;
			font-size: 18px;
			font-weight: bold;
			&:not(:first-child) {
				margin-top: 20px;
			}
		}
		.vid-label {
			color: white !important;
		}
	}
	.download-links {
		ul {
			margin-top: 10px;
			list-style: none;
			margin-left: 0;
			li {
				display: inline-block;
				padding-top: 0;
				line-height: 15px;
			}
			li:not(:last-child) {
				border-right: 1px solid #909090;
				padding-right: 7px;
				margin-right: 5px;
			}
		}
		a {
			font-size: 13px;
			font-style: italic;
		}
	}
	@media only screen and (min-width: 1500px) {
		.left-block {
			div, p {
				font-size: 18px;
			}
		}
		.vid-label {
			font-size: 14px !important;
			padding: 5px;
		}
	}
	@media only screen and (max-width: 767.98px) {
		&.wrapper.product-page #hero {
			height: 100vh !important;
			overflow-y: auto;
			> div {
				height: unset;
			}
		}
		.left-block {
			h1 {
				color: $w-gold;
				text-align: center;
			}
			.eddy-profile ~ a {
				padding-top: 15px;
				color: $w-gold !important;
			}
			padding-bottom: 10px;
			border-bottom: 2px solid $w-gold;
		}
		.right-block {
			padding-top: 5px;
			background-color: rgba(0,0,0,.5);
			h1 {
				text-align: center;
			}
			.row {
				padding: 0 15px;
				.vid-label {
					margin-top: 5px;
					font-weight: normal !important;
					text-align: center;
				}
			}
		}
	}
}
@media only screen and (max-width: 1024px) {
	.right-block {
		padding: 2rem 2rem 0;
		.video-pane .row {
			margin-bottom: 0;
			>div {
				margin-bottom: 1.5rem;
				padding: 0 !important;
			}
			.vid-label {
				font-size: 1rem;
				font-weight: bold;
			}
		}
	}
	.left-block {
		.section-1 {
			h1 {
				font-size: 24px;
			}
		}
		.section-2 {
			.cta-wrapper {
				margin-top: 1.5rem;
				border-top: 1px solid $w-gold;
				padding-top: 1.5rem;
			}
		}
		padding: 2rem 2rem 0;
	}
}
@media only screen and (max-width: 767.98px) {
	.cta-wrapper {
		display: none !important;
	}
}
