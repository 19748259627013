@media only screen and (max-width: 767.98px) {
	.wrapper {
		p { letter-spacing: -.25px !important; }
		.scroll-hint {
			transform: scale(.8);
		}
		#hero {
			height: 100vh;
			.sams { 
				visibility: hidden;
			}
			.hero-back {
				max-width: unset !important;
				height: 100% !important;
				width: auto !important;
				left: calc(-100% + 50vw);
			}

			div {
				h1 {
					padding: 0 !important;
					font-size: 1.4rem !important;
					line-height: 1.8 !important;
					font-weight: $semibold !important;
				}
				&:before {
					background-size: 35vh !important;
					background-position: center 10vh !important;
				}
				p {
					margin-top: unset;
				}
				.fine-print {
					position: relative;
					p{
						color: white !important;
						text-shadow: 0 0 2px black;
						&:last-child {
							font-size: 12px !important;
						}
					}
					&:before {
						content: "";
						position: absolute;
						left: -100px;
						top: 0;
						display: block;
						width: 1500px;
						height: 100%;
						background-color: transparentize(black, .7);
						z-index: -1;
					}
				}
			}
		}
	}
	.foot {
		display: none !important;
		text-align: center;
		line-height: 1 !important;
		min-height: 55px;
		div div {
			float: none !important;
			display: block;
			line-height: 1;
			margin-top: 18px;
		}
		span {
			display: block;
			margin-bottom: -10px;
			padding-top: 10px;
		}
	}
	.wrapper {
		&.landing {
			#hero div p {
				font-size: .875rem; 
			}
			#headline .section-constrainer div{
				.headline-msg {
					h2 {
						padding-right: 0;
						color: $w-gold;
						span {
							display: inline;
							padding: 5px;
						}
					}
					p {
						text-align: left; 
						padding: 0;
					}
				}

			}
			#stats .medium-4 {
				padding: 0;
				p {
					padding: 0;
				}
			}
			#industry-bar {
				.small-9 {
					width: 83.333%;
					h3 {
						text-align: center;
					}
				}
			}
			#product-tiles .section-constrainer {
				@include flex-direction(column);
				.product {
					width: 100% !important;
					margin-left: 0 !important;
					.tile-frame {
						padding: 80px 20px 30px;
						&:before {
							width: 100px;
							height: 100px;
							top: -30px;
							left: calc(50% - 50px;);
						}
						.product-name {
							padding-left: 0;

						}
						.cta { display: none; }
					}
				}
			}
			#industry-bar p{
				text-align: left;
			}
		}
		&.product-page {
			.head-bar {
				background-color: transparentize(black, .2);
			}
			#hero {
				height: unset;
				.product-summary {
					@include flexbox;
					@include flex-direction(column);
					padding: 1.33rem 8.3vw;
					h1 { order: 1; }
					.subtitle { order: 2; }
					p { order: 5; }
					.div:not(.cta-btn) {
						padding-right: 0;
					}
					.vid-wrapper { order: 3; }
					.cta-wrapper { 
						padding-top: 1rem;
						order: 4; 
						>div { font-size: 1rem; }
						.mobile-cta {
							margin-top: .667rem;
							padding: 0;
						}
						.note {
							margin-bottom: 1.333rem;
							font-size: 12px;
						}
					}
				}
				.scroll-hint {display: none;}
			}
			#details {
				padding: 2rem 0;
				.section-limiter div p {
					text-align: left;
				}
			}
			#vid-scroll {
				margin-top: 0;
				padding: 2rem 0;
				background-color: #ebebeb;
				.section-constrainer { padding-bottom: 0; }
				.orbit-wrapper ul.orbit-container li.orbit-slide .cell, .vid-grid-row .cell {
					padding: 0 !important;
					.vid-label {
						margin-bottom: 1.3rem;
					}
				}
			}
			#trainers {
				padding: 0;
				background-color: #ebebeb;
				.trainer-profile p {
					text-align: left;
				}
				.trainer-pic {
					background-position: center;
					margin-bottom: 2rem;
				}
			}
			&.garage {
				#hero {
					background-position: center;
				}
				#additional-content {
					.section-constrainer .resource-group ul {
						margin-left: 0;
						li {
							padding: 0;
							border: none;
							.vid-cell {
								padding-right: 0;
								margin-bottom: 10px;
							}
						}
					}
				}
			}
			&.dock {
				#hero {
					background-position: -33vw;
				}
			}
		}
		&.about {
			#hero {
				height: unset;
				.copy-wrapper {
					column-count: 1;
				}
			}
			#whoWeAre {
				padding-top: 2rem;
				.people {
					margin-bottom: 2rem;
				}
				.tile-frame {
					&:nth-child(even) {
						margin-top: 2rem;
					}
					.tile {
						padding: 1.5rem 1rem;
						background-color: $w-black !important;
						.profile-pic {
							left: unset;
							right: 1rem;
							width: 100px;
							height: 100px;
						}
						.name-and-title {
							display: initial;
							padding-left: 0;
							h2 {
								font-size: 1.2rem;
							}
							h6 {
								width: 50%;
								margin-bottom: 1rem;
								font-size: 1rem;
								line-height: 1.2;
								font-weight: $semibold;
								margin-left: 0;
								margin-bottom: .667rem;
							}
						}
						.description {
							padding-left: 0;
						}
						.contact {
							position: relative;
							margin-top: 0 !important;
							margin-bottom: 1rem !important;
							a {
								position: initial !important;
							}
						}
					}
				}
			}
			#industry-partners {
				.customers {
					padding-bottom: 0;
					.logos {
						@include flex-direction(column);
						img {
							height: 25px;
							margin-bottom: 10px;
							object-fit: contain;
							margin-right: 0;
							&.smaller {
								height: 20px;
							}
						}
					}
				}
			}
			#meet-the-trainers {
				background-position: right;
				.trainer {
					.headline {
						display: initial !important;
						h2 {
							font-size: 1.2rem;
							margin-bottom: 0;
						}
						.products {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
// sign-up block
	.cta {
			display: none !important;
		}
		.mobile-cta {
			display: block !important;
			margin-top: auto;
			margin-bottom: auto;
		}
	.reveal#mobile-sign-up-message{
		position: fixed;
		width: 90vw;
		height: 90vh;
		left: 5vw;
		top: 5vh;
		bottom: unset;
		min-height: unset;
		background-color: transparentize(white, .05);
		border-radius: 5px;
		padding: 3.33rem 1.5rem 0;
		h1 {
			font-weight: $semibold;
			color: $w-blue;
			font-size: 1.5rem;
			margin-bottom: 2rem;
		}
		p { color: #606060; }
		.close-trigger {
			margin-top: 6rem;
			font-weight: $semibold;
			color: $w-blue;
			text-align: center;
			width: 100%;
		}
	}
}

@media only screen and (max-width: 767.98px),
only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2), 
only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
  	.wrapper {
		p { letter-spacing: -.25px !important; }
		.scroll-hint {
			transform: scale(.8);
		}
		#hero {
			height: 100vh;
			.sams { 
				visibility: hidden;
			}
			.hero-back {
				position: absolute;
		    	bottom: 0;
		    	z-index: -1;
		    	width: 100% !important;
		    	height: auto;
		    	left: unset !important;
			}

			div {
				h1 {
					padding: 0 !important;
					font-size: 1.4rem !important;
					line-height: 1.8 !important;
					font-weight: $semibold !important;
				}
				&:before {
					background-size: 35vh !important;
					background-position: center 10vh !important;
				}
				p {
					margin-top: unset;
				}
				.fine-print {
					position: relative;
					p{
						color: white !important;
						text-shadow: 0 0 2px black;
						&:last-child {
							font-size: 12px !important;
						}
					}
					&:before {
						content: "";
						position: absolute;
						left: -100px;
						top: 0;
						display: block;
						width: 1500px;
						height: 100%;
						background-color: transparentize(black, .7);
						z-index: -1;
					}
				}
			}
		}
		#headline .section-constrainer div .headline-msg p {
			width: 100%;
		}
		#stats .section-constrainer >div >div {
			width: 100%;
		}
		#product-tiles {
			.section-constrainer {
				display: block;
				.product {
					width: 100% !important;
					margin-left: 0 !important;
				}
			}
		}
	}
	.foot {
		display: none !important;
		text-align: center;
		line-height: 1 !important;
		min-height: 55px;
		div div {
			float: none !important;
			display: block;
			line-height: 1;
			margin-top: 18px;
		}
		span {
			display: block;
			margin-bottom: -10px;
			padding-top: 10px;
		}
	}
	.wrapper {
		.head-bar {
			@include justify-content(left);
			padding: 0 1rem;
		}
		.navigation { display: none !important; }
		.support {display: none !important}
		.mobile-nav {
			display: block;
		}
		&.landing {
			#hero div p {
				font-size: .875rem; 
			}
			#headline .section-constrainer div{
				.headline-msg {
					h2 {
						padding-right: 0;
						color: $w-gold;
						span {
							display: inline;
							padding: 5px;
						}
					}
					p {
						text-align: left; 
						padding: 0;
					}
				}

			}
			#stats .medium-4 {
				padding: 0;
				p {
					padding: 0;
				}
			}
			#industry-bar {
				.small-9 {
					width: 83.333%;
					h3 {
						text-align: center;
					}
				}
			}
			#product-tiles .section-constrainer {
				@include flex-direction(column);
				.product {
					width: 100% !important;
					margin-left: 0 !important;
					.tile-frame {
						padding: 80px 20px 30px;
						&:before {
							width: 100px;
							height: 100px;
							top: -30px;
							left: calc(50% - 50px;);
						}
						.product-name {
							padding-left: 0;

						}
						.cta { display: none; }
					}
				}
			}
			#industry-bar p{
				text-align: left;
			}
		}
		&.product-page {
			.head-bar {
				background-color: transparentize(black, .2);
			}
			#hero {
				height: unset;
				.product-summary {
					@include flexbox;
					@include flex-direction(column);
					padding: 1.33rem 8.3vw;
					h1 { order: 1; }
					.subtitle { order: 2; }
					p { order: 5; }
					.div:not(.cta-btn) {
						padding-right: 0;
					}
					.vid-wrapper { order: 3; }
					.cta-wrapper { 
						padding-top: 1rem;
						order: 4; 
						>div { font-size: 1rem; }
						.mobile-cta {
							margin-top: .667rem;
							padding: 0;
						}
						.note {
							margin-bottom: 1.333rem;
							font-size: 12px;
						}
					}
				}
				.scroll-hint {display: none;}
			}
			#details {
				padding: 2rem 0;
				.section-limiter div p {
					text-align: left;
				}
				.two-by-vid {
					padding-left: 0 !important;
					.mod-vid-grid {
						width: calc(100% - .5rem) !important;
						&:first-child {
							margin-left: 0 !important;
						}
					}
				}
			}
			#vid-scroll {
				margin-top: 0;
				padding: 2rem 0;
				background-color: #ebebeb;
				.section-constrainer { padding-bottom: 0; }
				.orbit-wrapper ul.orbit-container li.orbit-slide .cell {
					padding: 0 !important;
					width: 100% !important;
					.vid-label {
						margin-bottom: 1.3rem;
					}
				}
			}
			#trainers {
				padding: 0;
				background-color: #ebebeb;
				.trainer-profile p {
					text-align: left;
				}
				.trainer-pic {
					margin-top: 1rem;
					background-position: center;
					margin-bottom: 2rem;
				}
			}
			&.garage {
				#hero {
					background-position: center;
				}
				#additional-content {
					.section-constrainer .resource-group ul {
						margin-left: 0;
						li {
							padding: 0;
							border: none;
							width: 100% !important;
							.vid-cell {
							width: 100% !important;
								padding-right: 0;
								margin-bottom: 10px;
							}
						}
					}
				}
			}
			&.dock {
				#hero {
					background-position: -33vw;
				}
			}
		}
		&.about {
			#hero {
				.copy-wrapper {
					column-count: 1;
				}
			}
		}
	}
// sign-up block
	.cta {
			display: none !important;
		}
		.mobile-cta {
			display: block !important;
			margin-top: auto;
			margin-bottom: auto;
		}
	.reveal#mobile-sign-up-message, .reveal#mobile-login-message{
		position: fixed;
		width: 90vw;
		height: 90vh;
		left: 5vw;
		top: 5vh !important;
		bottom: unset;
		min-height: unset;
		background-color: transparentize(white, .05);
		border-radius: 5px;
		padding: 3.33rem 1.5rem 1rem;
		h1 {
			font-weight: $semibold;
			color: $w-blue;
			font-size: 1.5rem;
			margin-bottom: 2rem;
		}
		p { color: #606060; }
		.close-trigger {
			margin-top: 6rem;
			font-weight: $semibold;
			color: $w-blue;
			text-align: center;
			width: 100%;
		}
	}
	.reveal#mobile-login-message {
		padding-top: 2.33rem;
		.btn-holder {
			margin-top: 1.667rem;
			padding-top: 2rem;
			border-top: 1px solid #ccc;
			text-align: center;
		}
	}
}
@media screen and (orientation: portrait) {
	.reveal#mobile-login-message {
		padding: 2.33rem 2rem 2rem;
		transform: rotate(-90deg);
		transform-origin: top left;
		width: 70vh;
		min-width: 70vh;
		max-width: 70vh;
		height: 80vw;
		min-height: 80vw;
		max-height: 80vw;
		min-height: unset;
		top: 85vh !important;
		left: 10vw !important;
		bottom: unset;
		left:unset;
		right: unset;
	}
 }
@media only screen and (max-width: 1024px) {
 	.head-bar {
 		.navigation {
 			display: none;
 		}
 		.mobile-nav {
 			display: block;
 		}
 	}
 	.about {
 		#hero {
 			.copy-wrapper {
 				column-count: 1 !important;
 			}
 		}
 		section#whoWeAre {
 			.tile-frame {
 				width: 100% !important;
 				padding: 0 !important;
 				&:nth-child(even) {
 					margin-top: 4rem;
 				}
 				.tile {
 					padding: 1.5rem 2.67rem;
 					.profile-pic {
 						top: -1rem;
 						left: -1rem;
 						width: 125px;
 						height: 125px;
 					}
 					.name-and-title {
 						position: relative;
 						padding-left: 5.5rem;
 						@include flexbox;
 						@include align-items(baseline);
 						h2 {
 							font-weight:$semibold; 
 						}
 						h6 {
 							margin-left: 1.2rem;
 							margin-bottom:0;
 						}
 						.contact {
 							position: initial;
 							margin-bottom: 2.33rem;
 							a {
 								position: absolute;
 								left: 5.5rem;
 								top: 1.72rem;
 							}
 						}
 					}
 					.description {
 						padding-left: 5.5rem;
 					}
 				}
 			}
 		}
 		section#meet-the-trainers {
 			.section-constrainer {
 				width: 83.33% !important;
 			}
 		}
 	}
}
@media only screen and (max-width: 880px) and (min-width: 640px) {
	.two-by-vid {
		padding-left: 3rem !important;
		display: block !important;
		.mod-vid-grid {
			width: 100% !important;
			margin-left: 0 !important;
			&:first-child {
				margin-bottom: 1rem;
			}
		}
	}
	.profile-wrapper {
		margin-top: unset !important;
	}
}
