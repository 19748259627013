$transition-duration: 0.3s;
$max-content-height: 1000px;

/* Style for the collapsible container */
.collapsible {
  cursor: pointer;
}
  
  /* Define styles for the content within the collapsible container */
.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height $transition-duration ease-in-out;

  ul {
    padding: 0;
    list-style: none;

    li {
      padding: 5px 0;
    }
  }

  /* Define the open and closed states */
  &.open {
    max-height: $max-content-height; /* Use the variable for content height */
  }
}


#catalog {
  li {
    color: red;
  }
}