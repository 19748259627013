html { 
	overflow-x: hidden;
}
body {
	height: 100vh;
	width: 100vw;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	
	.foot {
		display: block;
		background-color: $w-black;
		font-size: .8rem;
		line-height: 4rem;
		color: white;
		letter-spacing: .25px;
		.right {
			float: right;
			ul {
				margin-bottom: 0;
				li {
					display: inline;
					padding-left: 1rem;
					a {
						color: white;
						border-bottom: 1px solid rgba(255,255,255,.5);
						line-height: 4rem;
					}
				}
			}
			a {
				color: $w-gold;
			}
		}
	}
}
.btn {
	display: inline-block;
	border-radius: 20px;
	height: 40px;
	font-size: 1.2rem;
	line-height: 40px;
	color: white;
	background-color: $w-blue;
	padding: 0 1.33rem;
	box-shadow: -0.035px 2px 4.85px 0.15px rgba(0, 0, 0, 0.25);
	transition: all $trans-typical;
	&:hover, &:focus {
		background-color: darken($w-blue, 5%);
		color: white;
		box-shadow: -0.035px 2px 4.85px 0.15px rgba(0, 0, 0, 0.4);
	}
}
a {
	&:focus {
		outline: none;
	}
}
p {
	&.bigger {
		font-size: 1.125rem;
		line-height: 1.6875rem;
	}
	&.note {
		font-size: .8rem;
		line-height: 1.4rem;
	}
}
.resp-vid-wrapper {
	position: relative;
	width: 100%;
	padding: 56.25% 0 0 0;
	background-color: black;
	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}
}
.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	.head-bar {
		padding: 0 2rem;
		position: relative;
		width: 100%;
		height: 80px;
		margin-bottom: -80px;
		@include flexbox;
		@include align-items(center);
		border-bottom: 2px solid $w-gold;
		z-index: 100;
		background-color: transparentize(black, .5);

		.brandmark {
			width: 275px;
			height: 40px;
			padding-top: 7px;
			padding-left: 50px;
			background-image: url(../img/logo-solid-g_sm.png);
			background-size: 40px;
			background-position: center left;
			background-repeat: no-repeat;
			z-index: 10;
			position: relative;
		}
	}
	.navigation {
		@include flexbox;
		@include justify-content(center);
		position: absolute;
		width: calc(100% - 4rem);
		padding-top: 21px;
		a, li { 
			color: $w-gold;
			cursor: pointer;
		}
		a {
			transition: color $trans-typical;
			&:hover {
				color: lighten($w-gold, 30%);
			}
		}
		>ul {
			list-style-type: none;
			margin-left: 0;
			>li {
				display: inline;
				position: relative;
				&:not(:last-child) {
					padding-right: 2rem;
					border-right: 1px solid $w-gold;
				}
				&:not(:first-child) {
					padding-left: 2rem;
				} 
				&.dropdown {
					position: relative;
					&:after {
						content: "";
						position: absolute;
						right: 15px;
						top: 8px;
						border-top: 6px solid $w-gold;
						border-left: 4px solid transparent;
						border-right: 4px solid transparent;
					}
					&.active:after {
						border-top: 6px solid white;
					}
					.drop-menu {
						position: absolute;
						left: 0;
						top: 100%;
						width: 100%;
						max-height: 0;
						overflow: hidden;
						text-align: center;
						transition: $trans-typical max-height;
						padding-top: 10px;
						ul {
							list-style-type: none;
							border-top: 1px solid transparentize($w-gold, .5);
							background-color: transparentize(black, .3);
							border-radius: 0 0 3px 3px;
							margin-left: 0;

							li {
								position: relative;
								padding:5px;
								font-size: 14px;
								line-height: 1.5;
								transition: background-color $trans-typical;
								a { 
									transition: color $trans-typical;
									display: block;
									width: 100%;
									height: 100%; 
								}
								&:hover {
									background-color: transparentize(black, .05);
								}
								&:not(:last-of-type):after {
									content:"";
									position: absolute;
									bottom: 0;
									left: calc(50% - 2px;);
									width: 4px;
									border-top: 2px solid $w-gold;
								}
							}
						}
					}
					&:hover {
						.drop-menu {
							max-height: 500px;
							padding-bottom: 10px;
						}
					}
				}
				&.active {
					color: transparentize(white, .2);
					text-shadow: 0 0 3px transparentize(black, .25);
					font-weight: bold;
				}
			}
		}
	}
	.support {
		@include flexbox;
		z-index: 110;
		margin-left: auto;
		margin-bottom: -7px;
		a {
			color: $w-khaki-light;
			text-decoration: underline;
			font-size: .87rem;
			cursor: pointer;
		}
	}

	.social-container {
		@include flexbox;
		margin-left: auto;
		a {
			display: block;
			width: 30px;
			height: 30px;
			opacity: .8;
			background-size: contain;
			background-repeat: no-repeat;
			&:not(:last-of-type) {
				margin-right: 10px;
			}
			&.fb {
				background-image: url(../img/fb-ico-kh.png);
			}
			&.linked {
				background-image: url(../img/linked-ico-kh.png);
			}
			&.insta {
				background-image: url(../img/insta-ico-kh.png);
			}
		}
	}
	.nav-mask {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		background-color: transparent;
		&.visible {
			display: block;
			background-color: transparentize(black, .5);
		}
		z-index: 1000;
	}
	.mobile-nav { 
		z-index: 1001;
		display: none;
		position: absolute;
		right: 0;
		top: 0;
		padding: 20px 1rem 20px 10px;
		transition: background $trans-typical;
		@-webkit-keyframes before-rotate {
			0% { transform: rotate(deg); }
			100% { transform: rotate(135deg); }
		}
		@-webkit-keyframes after-rotate {
			0% { transform: rotate(deg); }
			100% { transform: rotate(225deg); }
		}
		.nav-trigger {
			position: absolute;
			display: block;
			width: 26px; 
			height: 22px;
			right: 20px;
			top: 32px;
			transition: transform .1s linear;
		
			div {
				width: 100%;
				height: 2px;
				background-color: $w-gold;
				transform-origin: 13px 1px;
				transform: translateY(5px);
			}
			div:before, div:after {
				content:"";
				display: block;
				position: absolute;
				transform-origin: 13px 1px;
				height: 2px;
				width: 100%;
				background-color: $w-gold;
				transition: top .1s linear;
			}
			div:before {
				top: -9px;
			}
			div:after {
				top: 9px;
			}
			&.animate-outer {
				div { 
					background-color: transparent;
					transform: rotate(90deg);
					&:before {
						top:0;
						animation: before-rotate .5s forwards;
						animation-delay: .1s;
					}
					&:after {
						top:0;
						animation: after-rotate .5s forwards;
						animation-delay: .1s;
					}
				}
			}
		}
		.mobile-drawer {
			width: 0;
			overflow: hidden;
			
			text-align: center;
			ul {
				margin-left: 0;
				list-style-type: none;
				li {
					margin-bottom: 1rem;

					&.product-nav {
						padding-top: 5px;
						border-top: 1px solid $w-khaki-light;
						border-bottom: 1px solid $w-khaki-light;
						color: white;
						font-weight: $semibold;
						text-transform: uppercase;
						font-size: 12px;
						ul {
							margin-top: 10px;
						}
						li{ text-transform: none; }
					}
					&.login-message-trigger a {
						font-weight: $light;
					}
				}
				a {
					color: $w-khaki-light;
					font-size: 1.2rem;
					line-height: 1;
					white-space: nowrap;
					&.active {
						color: $w-gold;
						font-weight: $semibold;
					}
				}
			}
			.mobile-social-container {
				@include flexbox;
				@include justify-content(center);
				padding-top: 1rem;
				border-top: 1px solid $w-khaki-light;
				margin-left: auto;
				a {
					display: block;
					width: 30px;
					height: 30px;
					opacity: .8;
					background-size: contain;
					background-repeat: no-repeat;
					&:not(:last-of-type) {
						margin-right: 15px;
					}
					&.fb {
						background-image: url(../img/fb-ico-wh.png);
					}
					&.linked {
						background-image: url(../img/linked-ico-wh.png);
					}
					&.insta {
						background-image: url(../img/insta-ico-wh.png); 
					}
				}
			}
			.mobile-support {
				a {
					padding: 5px 10px 0;
					color: $w-khaki-light;
					font-weight: 300;
					border-top: .5px solid $w-khaki-light;
				}
			}
		}
		&.open {
			background-color: transparentize($w-black, .1);
			.mobile-drawer {
				padding: 0 1rem;
				transition: width $trans-typical;
				width: calc(100vw - 27px);
			}
		}
	}
	.scroll-hint {
		position: absolute;
		bottom: 10px;
		img {
		width: 40px;
		}
	}
	.bounce { 
		animation: bounce 5s ease-out infinite;
		animation-delay: 5s;
	}
	@keyframes bounce {
		0% {bottom: 10px;}
		3% {bottom: 7px;}
		6% {bottom: 10px;}
		9% {bottom: 7px;}
		15% {bottom: 10px;}
	}
}
.hidden { display: none;}
body.navigating {
	overflow-y: hidden;
	.mobile-mask { display: block; }
}

@media only screen and (max-width : 640px) {
	.head-bar {
		@include justify-content(center);
		.login-trigger {display: none;}
	}
}
@media only screen and (max-width : 700px) { 
	.wrapper {
		.head-bar {
			@include justify-content(left);
			padding: 0 1rem;
		}
		.navigation { display: none !important; }
		.social-container { display: none !important;}
		.mobile-nav {
			display: block;
		}
	}
}


